import React from "react"

import Container from "../../components/Container"
import GooglePlay from "../../images/google-play_white.png"
import AppStore from "../../images/app-store_white.png"

import "./index.scss"

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <p>
          Сума кредиту: від 1000 до 200000 грн. Строк кредиту: від 6 до 60 міс.
          Процентна ставка за кредитом: 0,01% річних (фіксована). Щомісячна
          комісія: від 2,2 до 3,9% (нараховується на початкову суму кредиту).
          Реальна річна процентна ставка: від 48,15 до 154,17% річних (залежить
          від умов кредитування).
        </p>
        <p>
          Детальна інформація на сайті tascombank.ua або за телефоном 0 800 503
          580 (дзвінки по Україні безкоштовні). Тільки для повнолітніх.
        </p>
        <p>
          Калькулятор: розрахувати кредит можна на офіційному сайті Банку у вкладці «Калькулятор» <a href="https://tascombank.ua/you/credits/refinansuvannia" target="_blank">за посиланням</a>
        </p>
        <p>
        Істотні характеристики за споживчим кредитом «Рефінансування» <a href="https://tascombank.ua/you/credits/refinansuvannia" target="_blank">за посиланням</a>
        </p>
        <p>
        Попередження та можливі наслідки для користувача продуктом «Рефінансування» <a href="https://tascombank.ua/you/credits/refinansuvannia" target="_blank">за посиланням</a>
        </p>
        <div className="footer__links">
          {/* <a target={'_blank'} href={'https://play.google.com/store/apps/details?id=online.kapowai.tas2u'}>
                        <img src={GooglePlay} alt={'Google Play'} />
                    </a> */}
          {/* <a target={'_blank'} href={'https://apps.apple.com/app/tas2u/id1348286505?ls=1'}>
                        <img src={AppStore} alt={'App Store'} />
                    </a> */}
        </div>
        <div className="footer__text">
          <span>AT "ТАСКОМБАНК"</span>
          <span>Ліцензія НБУ №84 від 25.10.2011.</span>
          <span>Реєстрація НБУ №45 від 21.10.1991.</span>
          <span>м. Київ, вул. Симона Петлюри, 30.</span>
        </div>
      </Container>
    </div>
  )
}

export default Footer
